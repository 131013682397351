import {
  ADMIN_CONFIGURATION,
  GAMES_COLLECTION_NAME,
} from '@/constants/firestore'
import { mainOddsFormatter } from '@/utils/oddsFormater'
import {
  collection,
  doc,
  documentId,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { gamesConverter } from './converter'
import { firebaseDb } from './init'

const marketList = [
  'Moneyline',
  'Total Points',
  'Total Goals',
  'Total Runs',
  'Point Spread',
  'Puck Line',
  'Run Line',
]

export const gameOddsSnapshot = (
  gameId,
  setOdds,
  setTotalCount,
  oddsFormatter,
  formatter = mainOddsFormatter,
) => {
  const queries = collection(
    firebaseDb,
    `${GAMES_COLLECTION_NAME}/${gameId}/odds`,
  )

  const unsubscribeGameOdds = onSnapshot(query(queries), (querySnapshot) => {
    if (!querySnapshot.docChanges().length) return
    const queriedOdds = querySnapshot.docs.map((doc) => doc.data())

    const filteredQueries = queriedOdds.filter(
      (qry) => qry.is_main === true && marketList.includes(qry.market_name),
    )
    setTotalCount(oddsFormatter(queriedOdds))
    setOdds(formatter(filteredQueries))
  })
  return unsubscribeGameOdds
}

export const gameAllOddsSnapshot = (
  gameId,
  setOdds,
  formatter = mainOddsFormatter,
) => {
  const queries = collection(
    firebaseDb,
    `${GAMES_COLLECTION_NAME}/${gameId}/odds`,
  )

  const unsubscribeGameOdds = onSnapshot(query(queries), (querySnapshot) => {
    if (!querySnapshot.docChanges().length) return
    const queriedOdds = querySnapshot.docs.map((doc) => doc.data())
    setOdds(formatter(queriedOdds))
  })
  return unsubscribeGameOdds
}

export const gameMainOddsSnapshot = (
  gameId,
  setOdds,
  formatter = mainOddsFormatter,
) => {
  const queries = [
    collection(firebaseDb, `${GAMES_COLLECTION_NAME}/${gameId}/odds`),
    where('is_main', '==', true),
    where('market_name', 'in', [...marketList]),
  ]

  const unsubscribeGameOdds = onSnapshot(query(...queries), (querySnapshot) => {
    if (!querySnapshot.docChanges().length) return
    const queriedOdds = querySnapshot.docs.map((doc) => doc.data())
    setOdds(formatter(queriedOdds))
  })
  return unsubscribeGameOdds
}

export const gamesSnapshot = ({ live, sport, activeLeague, setGames }) => {
  const gamesQueries = [
    // TODO: update collection name when refactoring finished
    collection(firebaseDb, GAMES_COLLECTION_NAME).withConverter(gamesConverter),
    where('is_live', '==', live),
  ]
  if (sport) gamesQueries.push(where('sport', '==', sport))
  if (activeLeague) gamesQueries.push(where('league', '==', activeLeague))
  gamesQueries.push(orderBy('start_date'))

  const unsubscribeGames = onSnapshot(
    query(...gamesQueries),
    (querySnapshot) => {
      const queriedGames = querySnapshot.docs
        .map((doc) => doc.data())
        .filter((data) => {
          if (data.isPaused) return false
          return true
        })
      setGames(queriedGames)
    },
    (error) => console.error(error),
  )
  return unsubscribeGames
}

export const gameOddSnapshotByIds = (gameId, oddIds, setData) => {
  const collectionRef = collection(
    firebaseDb,
    `${GAMES_COLLECTION_NAME}/${gameId}/odds`,
  )
  const oddsQuery = query(collectionRef, where(documentId(), 'in', oddIds))

  const unsubscribeGameOddById = onSnapshot(oddsQuery, (querySnapshot) => {
    const queriedOdds = querySnapshot.docs.map((doc) => doc.data())
    setData(queriedOdds)
  })
  return unsubscribeGameOddById
}

export const scoresSnapshot = (gameId, setScores) => {
  const gameScoresRef = doc(
    firebaseDb,
    `${GAMES_COLLECTION_NAME}/${gameId}/info/scores`,
  )

  const unsubscribeGameScores = onSnapshot(gameScoresRef, (querySnapshot) => {
    setScores(querySnapshot.data())
  })
  return unsubscribeGameScores
}

export const happyHourListener = (setHappyHour) => {
  const happyHourRef = doc(firebaseDb, `${ADMIN_CONFIGURATION}/happy-hour`)

  const unsubscribeHappyHour = onSnapshot(happyHourRef, (querySnapshot) => {
    setHappyHour(querySnapshot.data()?.isActive)
  })
  return unsubscribeHappyHour
}

export const getCountTotal = async (query) => {
  try {
    const snapshot = await getCountFromServer(query)
    if (snapshot) {
      return snapshot.data().count
    } else {
      return null
    }
  } catch (error) {
    console.error('Error fetching document:', error)
    throw error
  }
}
export const oddsTotal = async (gameId) => {
  const oddsRef = collection(
    firebaseDb,
    `${GAMES_COLLECTION_NAME}/${gameId}/odds`,
  )
  const totalQuery = query(oddsRef)
  try {
    const count = await getCountTotal(totalQuery)
    return count
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getBetPoolsData = (setData) => {
  const tableQuery = query(collection(firebaseDb, 'bet-pools'))
  const unsubTableQuery = onSnapshot(tableQuery, (querySnapshot) => {
    const queriedTables = querySnapshot.docs.map((doc) => doc.data())
    setData(queriedTables)
  })
  return unsubTableQuery
}

export const getBetPoolById = (setData, poolId) => {
  const collectionRef = collection(firebaseDb, 'bet-pools')
  const tableQuery = query(collectionRef, where(documentId(), '==', poolId))
  const unsubTableQuery = onSnapshot(tableQuery, (querySnapshot) => {
    const queriedTable = querySnapshot.docs.map((doc) => doc.data())
    setData(queriedTable?.[0])
  })
  return unsubTableQuery
}

export const getPoolHistoryById = (setData, poolId) => {
  const tableQuery = query(collection(firebaseDb, `bet-pools/${poolId}/bets`))
  const unsubTableQuery = onSnapshot(tableQuery, (querySnapshot) => {
    const queriedTable = querySnapshot.docs.map((doc) => doc.data())
    setData(queriedTable)
  })
  return unsubTableQuery
}

export const getTexasPokerTables = (setData) => {
  const tableQuery = query(
    collection(firebaseDb, `casino-prod/texas-poker/tables`),
  )
  const unsubTableQuery = onSnapshot(tableQuery, (querySnapshot) => {
    const queriedTables = querySnapshot.docs.map((doc) => doc.data())
    setData(queriedTables)
  })
  return unsubTableQuery
}

export const getTexasPokerTableById = (setData, tableId) => {
  const tableRef = collection(firebaseDb, `casino-prod/texas-poker/tables`)
  const tableQuery = query(tableRef, where(documentId(), '==', tableId))
  const unsubTableQuery = onSnapshot(tableQuery, (querySnapshot) => {
    const queriedTable = querySnapshot.docs.map((doc) => doc.data())
    setData(queriedTable?.[0])
  })
  return unsubTableQuery
}

export const getTexasPokerTableHistoryById = (setData, tableId) => {
  const historyRef = collection(firebaseDb, `casino-prod/texas-poker/history`)
  const historyQuery = query(
    historyRef,
    where('tableId', '==', tableId),
    where('cards', '!=', []),
    orderBy('modifiedAt', 'desc'),
    limit(5),
  )
  const unsubHistoryQuery = onSnapshot(historyQuery, (querySnapshot) => {
    const queriedHistory = querySnapshot.docs.map((doc) => doc.data())
    setData(queriedHistory)
  })
  return unsubHistoryQuery
}
