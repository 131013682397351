import { toResultString } from '@/components/pages-partials/black-jack/components/game-area/helper'
import { BsvIcon } from '@/icons/index'
import { formatDate } from '@/utils/dates'
import { toPlainString } from '@/utils/toPlainString'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'
import { AgGridReact } from 'ag-grid-react'
import React, { useMemo } from 'react'

ModuleRegistry.registerModules([ClientSideRowModelModule])

const resultColors = {
  blackjack: 'bg-yellow-700',
  lose: 'bg-red-700',
  win: 'bg-green-700',
  push: 'bg-gray-700',
  bust: 'bg-red-700',
}

const HistoryTable = ({ allBets, bsvToUsd, onIdPress, isHome }) => {
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  const GameDate = ({ data }) => {
    return (
      <div className="text-sm font-medium capitalize flex gap-x-5 w-[100%]">
        <div className="p-2">
          <span>
            {formatDate(data?.date, 'MM/DD/YYYY') +
              ' ' +
              formatDate(data?.date, 'hh:mm A')}
          </span>
        </div>
      </div>
    )
  }

  const BetIdRenderer = ({ data }) => {
    return (
      <div className="text-sm font-medium  cursor-pointer" title={data?.id}>
        {data?.id}
      </div>
    )
  }

  const PayoutRenderer = ({ data }) => {
    const { bsvBet, userWinRates } = data
    const totalWinRate = userWinRates.reduce((acc, cur) => acc + cur, 0)
    const totalBsvWon = bsvBet * totalWinRate
    const totalWon = toPlainString(
      Number(bsvToUsd(totalBsvWon) || 0).toFixed(5),
    )
    return (
      <div
        className={`text-sm font-medium space-y-2 text-right ${
          totalWon > 0 ? 'text-yellow-600' : 'text-gray-600'
        }`}
      >
        <div className="flex gap-x-2 items-center">
          <BsvIcon className="w-4 h-4" />
          {totalBsvWon > 0 ? totalBsvWon.toFixed(5) : 0}
        </div>
        <div>
          {totalWon > 0 ? '+' : ''}$ {totalWon}
        </div>
      </div>
    )
  }

  const BetRenderer = ({ data }) => {
    const { bsvBet, doubleTx, userHands } = data
    const totalBetAmount = bsvBet * (doubleTx || userHands.length > 1 ? 2 : 1)
    return (
      <div className="text-sm font-medium space-y-2 cursor-pointer text-right">
        <div className="flex gap-x-2 items-center">
          <BsvIcon className="w-4 h-4" />
          {totalBetAmount.toFixed(5)}
        </div>
        <div>$ {Number(bsvToUsd(totalBetAmount) || 0.0).toFixed(5)}</div>
      </div>
    )
  }

  const ResultRenderer = ({ data }) => {
    const { userHands } = data
    let userHandFirstResult = null
    let userHandSecondResult = null
    if (userHands.length > 1) {
      userHandSecondResult = toResultString(userHands[1].result)
    }
    userHandFirstResult = toResultString(userHands[0].result)

    return (
      <div className="text-sm font-medium cursor-pointer flex flex-col space-y-2">
        <span
          className={`${resultColors[userHandFirstResult]} rounded-2xl px-2 py-0.5`}
        >
          {userHandFirstResult}
        </span>
        {userHandSecondResult && (
          <span
            className={`${resultColors[userHandSecondResult]} rounded-2xl px-2 py-0.5`}
          >
            {userHandSecondResult}
          </span>
        )}
      </div>
    )
  }

  const getRowId = useMemo(() => {
    return (params) => params.data.sendTx
  }, [])

  const onCellClicked = ({ data }) => {
    onIdPress(data)
  }

  const casinoColumnDefs = [
    {
      headerName: 'Bet ID',
      field: 'betID',
      cellRenderer: BetIdRenderer,
      minWidth: 150,
      onCellClicked,
    },
    {
      headerName: 'Bet',
      field: 'betPrice',
      cellRenderer: BetRenderer,
      minWidth: 150,
      onCellClicked,
    },
    {
      headerName: 'Payouts',
      field: 'payouts',
      cellRenderer: PayoutRenderer,
      minWidth: 150,
      onCellClicked,
    },
    {
      headerName: 'Time(UTC)',
      field: 'date',
      cellRenderer: GameDate,
      minWidth: 160,
      onCellClicked,
    },
    {
      headerName: 'Result',
      field: 'result',
      cellRenderer: ResultRenderer,
      minWidth: 160,
      onCellClicked,
    },
  ]

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: false,
    }
  }, [])

  return (
    <div
      className={`h-[500px] pb-[0.5] mx-auto ${
        isHome ? 'w-full' : 'w-[95%] 2xl:w-[97%]'
      } my-8 bg-black/70 rounded-md`}
      id="grid-wrapper"
    >
      <div style={gridStyle} className="ag-theme-quartz-dark">
        <AgGridReact
          rowData={allBets}
          columnDefs={casinoColumnDefs}
          defaultColDef={defaultColDef}
          animateRows
          getRowId={getRowId}
          rowHeight={70}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; ">No Results Found</span>'
          }
          overlayLoadingTemplate='<span class="plinko-loader"></span>'
          onFirstDataRendered={(params) => params.api.sizeColumnsToFit()}
        />
      </div>
    </div>
  )
}

export default HistoryTable
